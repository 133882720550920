import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';

import { getAuthData } from '../../../../../../../helpers/authStorage';
import classes from './styles.module.scss';
import Checkmark from './Checkmark';
import Feedback from './Feedback';

export default function Message({
  message,
  refetchMessages,
  onTranslate,
  onSpeak,
  isPlayingMessage,
  isLoadingSpeak,
  isTranslating,
}) {
  const { userId } = getAuthData();

  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(
    message.languageCode || 'en'
  );

  const isMyMessage = message.authorId === +userId;
  let messageText = message.content;

  let feedback;

  if (!messageText) {
    feedback = message.feedback;
    messageText = feedback?.feedbackNote;
  }

  const extendedData = JSON.parse(message.extendedData || '{}');

  let messagePoint = extendedData.status;

  if (
    extendedData.status === 'Onboard' &&
    extendedData.subStatus === 'Pending'
  ) {
    messagePoint = 'Offer';
  }

  const isAddressed = message.threadMessageEvents.some(
    (event) => event.eventType === 'Addressed'
  );

  const translation = message.translations?.find(
    (mess) => mess.languageCode === selectedLanguage
  );

  const handleLanguageChange = async (e) => {
    const newLanguageCode = e.target.value;
    setSelectedLanguage(newLanguageCode);

    // Check if the translation already exists
    if (
      !message.translations?.some(
        (trans) => trans.languageCode === newLanguageCode
      )
    ) {
      await onTranslate(message.id, newLanguageCode); // Call the translate method only if necessary
      refetchMessages(); // Update the messages to include the new translation
    }
  };

  const getEmojiForSentiment = (sentiment) => {
    if (sentiment === 'positive') return '✅';
    if (sentiment === 'negative') return '❌';
    return '⚪';
  };

  return (
    <section
      className={classNames(classes.Message, {
        [classes.my]: isMyMessage,
      })}
    >
      <header className={classes.header}>
        {isMyMessage ? (
          <h5>
            {t(
              'dashboardComponents.Chat.RightPanel.ChatWindow.MessagesList.Message.myMessage'
            )}
          </h5>
        ) : (
          <div className={classes.userHeaderContent}>
            <h5>
              {message.author?.userProfile?.firstName}’s{' '}
              {t(
                'dashboardComponents.Chat.RightPanel.ChatWindow.MessagesList.Message.message'
              )}
              : {t(`common.jobApplicationStatuses.${messagePoint}`)}{' '}
              {t(
                'dashboardComponents.Chat.RightPanel.ChatWindow.MessagesList.Message.point'
              )}
            </h5>

            <Checkmark
              isResolved={isAddressed}
              messageId={message.id}
              refetchMessages={refetchMessages}
            />
          </div>
        )}
        <div className={classes.messageTools}>
          {message?.languageCode && message?.content.length > 0 && (
            <select
              id={`language-${message.id}`}
              disabled={isTranslating}
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="es">Spanish</option>
              <option value="fr">French</option>
              <option value="tr">Turkish</option>
              {/* Add more languages as needed */}
            </select>
          )}
          {translation?.key && (
            <button
              className={classes.speakButton}
              type="button"
              onClick={() => {
                if (onSpeak && typeof onSpeak === 'function') {
                  onSpeak(
                    message.id,
                    translation.key,
                    translation.languageCode,
                    translation.content
                  ); // Call the provided onSpeak function
                }
              }}
              disabled={isPlayingMessage || isLoadingSpeak}
            >
              📢
            </button>
          )}
        </div>
      </header>
      <div className={classes.text}>
        {message.feedback !== null && (
          <Feedback
            rating={feedback?.feedbackRating}
            isRecommended={feedback?.isRecommended}
          />
        )}
        {translation?.content || messageText}
      </div>
      <footer className={classes.footer}>
        <div className={classes.createdAt}>
          {moment(message.createdAt).format('Do MMM YYYY hh:mm A')}{' '}
          {message?.dominantSentiment && (
            <span>
              | Dominant sentiment:{' '}
              {getEmojiForSentiment(message?.dominantSentiment)}
            </span>
          )}
        </div>
      </footer>
    </section>
  );
}
