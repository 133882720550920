import React, { useEffect, useRef } from 'react';

import Message from './Message';

import classes from './styles.module.scss';

export default function MessagesList({
  chatMessages,
  activeContact,
  refetchMessages,
  messageListHeight,
  onTranslate,
  onSpeak,
  isPlayingMessage,
  isLoadingSpeak,
  isTranslating,
}) {
  const messageListRef = useRef();

  useEffect(() => {
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [chatMessages]);

  return (
    <div
      className={classes.MessagesList}
      ref={messageListRef}
      style={{ height: messageListHeight }}
    >
      {chatMessages
        ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .map((message) => (
          <Message
            key={message.id}
            message={message}
            jobApplicationId={activeContact?.jobApplication.id}
            refetchMessages={refetchMessages}
            onTranslate={onTranslate}
            onSpeak={onSpeak}
            isPlayingMessage={isPlayingMessage}
            isLoadingSpeak={isLoadingSpeak}
            isTranslating={isTranslating}
          />
        ))}
    </div>
  );
}
