import { useEffect, useMemo, useCallback } from 'react';

import * as signalR from '@microsoft/signalr';

import { getAuthData } from '../helpers/authStorage';

export default function useComHubConnection(url) {
  const { accessToken } = getAuthData();
  const connection = useMemo(
    () =>
      new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => {
            return accessToken;
          },
        })
        .configureLogging(signalR.LogLevel.Information)
        .build(),
    [accessToken, url]
  );

  const startConnection = useCallback(async () => {
    try {
      await connection.start();
      console.log('Connected');
    } catch (error) {
      console.error(error);
    }
  }, [connection]);

  const endConnection = useCallback(async () => {
    try {
      await connection.stop();
    } catch (error) {
      console.error(error);
    }
  }, [connection]);

  useEffect(() => {
    startConnection();

    return () => {
      endConnection();
    };
  }, [startConnection, endConnection]);

  return {
    connection,
    startConnection,
    endConnection,
  };
}
