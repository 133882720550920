import React, { useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../../../../Tooltip';

import { UiContext } from '../../../../../../../../context/UiContext';
import ChatService from '../../../../../../../../services/ChatService';
import classes from './styles.module.scss';

export default function Checkmark({ isResolved, messageId, refetchMessages }) {
  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const markMessageAsResolved = async () => {
    try {
      setIsFetching(true);
      await ChatService.markMessageAsAddressed({ messageId });
      await refetchMessages();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.Checkmark}>
      {isResolved ? (
        <div className={classNames(classes.checkmark, classes.checked)} />
      ) : (
        <Tooltip
          text={t(
            'dashboardComponents.Chat.RightPanel.ChatWindow.MessagesList.Message.Checkmark.addressed'
          )}
          width={152}
        >
          <div className={classes.checkmark} onClick={markMessageAsResolved} />
        </Tooltip>
      )}
    </div>
  );
}
