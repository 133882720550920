import axios from 'axios';

import { COMHUB_API_URL, INTEL_API_URL } from '../constants/main';

async function getData({ orderBy, term, url }) {
  const response = await axios.get(url, {
    params: {
      Term: term,
      OrderBy: orderBy,
    },
  });

  return response.data;
}

const ChatService = {
  async getThreads({ term, orderBy }) {
    const url = `${COMHUB_API_URL}/jobcommunications`;
    return getData({
      url,
      term,
      orderBy,
    });
  },

  async getMessages({ jobApplicationId }) {
    const url = `${COMHUB_API_URL}/jobapplications/${jobApplicationId}/messages`;
    return getData({
      url,
    });
  },

  async sendMessage({ jobApplicationId, content }) {
    const url = `${COMHUB_API_URL}/jobapplications/${jobApplicationId}/messages`;
    const response = await axios.post(url, {
      content,
    });

    return response.data;
  },

  async speakMessage({
    messageId,
    messageTranslationKey,
    languageCode,
    content,
  }) {
    const url = `${INTEL_API_URL}/speech/messages/${messageId}/speak`;
    const response = await axios.post(url, {
      content,
      messageTranslationKey,
      languageCode,
    });

    return response.data;
  },

  async translateMessage({ messageId, languageCode }) {
    const url = `${COMHUB_API_URL}/messages/${messageId}/translate`;
    const response = await axios.post(url, {
      languageCode,
    });

    return response.data;
  },

  async pinContact({ jobApplicationId }) {
    const url = `${COMHUB_API_URL}/jobapplications/${jobApplicationId}/thread/pins`;
    const response = await axios.post(url);

    return response.data;
  },

  async unpinContact({ jobApplicationId }) {
    const url = `${COMHUB_API_URL}/jobapplications/${jobApplicationId}/thread/pins`;
    const response = await axios.delete(url);

    return response.data;
  },

  async markMessageAsAddressed({ messageId }) {
    const url = `${COMHUB_API_URL}/messages/${messageId}/events`;
    const payload = {
      eventType: 'Addressed',
    };

    const response = await axios.post(url, payload);

    return response.data;
  },

  async markMessageAsRead({ messageId }) {
    const url = `${COMHUB_API_URL}/messages/${messageId}/events`;
    const payload = {
      eventType: 'Read',
    };

    const response = await axios.post(url, payload);

    return response.data;
  },
};

export default ChatService;
