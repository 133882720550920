import React, { useState, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

import useDebouncedSearchWithHistory from '../../../hooks/useDebouncedSearchWithHistory';
import classes from './styles.module.scss';
import ChatService from '../../../services/ChatService';

export default function Chat() {
  const [activeContactId, setActiveContactId] = useState(null);
  const [orderBy, setOrderBy] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const jobApplicationId = searchParams.get('jobApplicationId');
    if (jobApplicationId) {
      setActiveContactId(+jobApplicationId);
    }
  }, [searchParams]);

  const { debouncedSearchTerm, searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'chatThreadsSearch'
  );

  const { data: chatThreads, refetch: refetchChatThreads } = useQuery({
    queryKey: ['chatThreads', orderBy, debouncedSearchTerm],
    queryFn: () =>
      ChatService.getThreads({
        orderBy: orderBy.toLowerCase(),
        term: debouncedSearchTerm,
      }),
    keepPreviousData: true,
    enabled: !!orderBy,
  });

  const contacts = [];
  chatThreads?.forEach((group) => contacts.push(...group.threads));

  const activeContact = contacts.find(
    (contact) => contact.jobApplication.id === activeContactId
  );

  return (
    <div className={classes.Chat}>
      <LeftPanel
        activeContactId={activeContactId}
        setActiveContactId={setActiveContactId}
        chatThreads={chatThreads}
        refetchChatThreads={refetchChatThreads}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchHistory={searchHistory}
      />
      <RightPanel
        refetchChatThreads={refetchChatThreads}
        activeContact={activeContact}
      />
    </div>
  );
}
