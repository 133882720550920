import React, { useState, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import TextInput from '../../../../../Form/TextInput';
import ChatService from '../../../../../../services/ChatService';
import { UiContext } from '../../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function MessageInput({
  activeContact,
  refetchMessages,
  handleTyping,
  isOthersTyping,
}) {
  const [message, setMessage] = useState('');
  const [moveArrow, setMoveArrow] = useState(false);
  const [activeFlag, setActiveFlag] = useState(false);

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const sendMessage = async () => {
    try {
      setIsFetching(true);
      setMessage('');
      await ChatService.sendMessage({
        jobApplicationId: activeContact.jobApplication.id,
        content: message,
      });
      await refetchMessages();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };
  const moveArrowFn = () => {
    setMoveArrow((prev) => !prev);
    setActiveFlag((prev) => !prev);
    setTimeout(() => {
      setMoveArrow((prev) => !prev);
      setActiveFlag((prev) => !prev);
    }, 700);
  };

  useEffect(() => {
    if ((message, message.length > 0)) {
      handleTyping();
    }
  }, [message]);

  return (
    <div className={classes.MessageInputWrapper}>
      <div className={classes.MessageInput}>
        <TextInput
          height={59}
          placeholder={t(
            'dashboardComponents.Chat.RightPanel.ChatWindow.MessageInput.placeholder'
          )}
          width="100%"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
        <div className={classes.sentWrapper}>
          <div className={classes.graybackgroundExpanded} />
          <div
            className={classNames(classes.greenbackgroundExpanded, {
              [classes.showGreenBg]: moveArrow,
            })}
          />
          <div
            className={classNames(classes.sent, {
              [classes.active]: activeFlag || message,
              [classes.moveArrow]: moveArrow,
              [classes.moveArrowLeft]: !moveArrow,
            })}
            onClick={() => {
              sendMessage();
              moveArrowFn();
            }}
          />
        </div>
      </div>
      {isOthersTyping && (
        <div className={classes.textAreaInfo}>
          <div className={classes.typingIndicator}>
            <p>{isOthersTyping} is typing...</p>
          </div>
        </div>
      )}
    </div>
  );
}
