import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import MessageInput from './MessageInput';
import MessagesList from './MessagesList';

import classes from './styles.module.scss';

import ChatService from '../../../../../services/ChatService';
import UserService from '../../../../../services/UserService';
import { getAuthData } from '../../../../../helpers/authStorage';

export default function ChatWindow({
  activeContact,
  connection,
  messageListHeight,
  refetchChatThreads,
}) {
  const [chatMessages, setChatMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isOthersTyping, setIsOthersTyping] = useState(null);
  const [speakedMessage, setSpeakedMessage] = useState(null);
  const [isPlayingMessage, setIsPlayingMessage] = useState(false);
  const [isLoadingSpeak, setIsLoadingSpeak] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const { userId } = getAuthData();

  let typingTimeout;

  const { data: userData } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const markMessageAsRead = async (messageId) => {
    try {
      await ChatService.markMessageAsRead({ messageId });
      refetchChatThreads();
    } catch (error) {
      console.log(error);
    }
  };

  const { data: fetchedMessages, refetch: refetchMessages } = useQuery({
    queryKey: ['chatMessages', activeContact?.jobApplication?.id],
    queryFn: () =>
      ChatService.getMessages({
        jobApplicationId: activeContact?.jobApplication?.id,
      }),
    enabled: !!activeContact?.jobApplication?.id,
    keepPreviousData: false,
  });

  useEffect(() => {
    if (fetchedMessages) {
      setChatMessages(fetchedMessages);
      refetchChatThreads();
    }
  }, [fetchedMessages]);

  useEffect(() => {
    if (connection) {
      connection.on('NewMessage', (message) => {
        if (+userId !== message.authorId) {
          setChatMessages((prevMessages) => [...prevMessages, message]);
          markMessageAsRead(message.id);
        }
      });

      connection.on('Typing', (user) => {
        console.log('Typing: ', user?.userName);
        setIsOthersTyping(user?.userName);
      });

      connection.on('StoppedTyping', () => {
        setIsOthersTyping(null);
      });

      if (activeContact.id) {
        const groupName = `${'group'}-${activeContact.id}`;
        connection
          .invoke('JoinGroup', groupName)
          .then(() => console.log(`Joined group: ${groupName}`))
          .catch((err) => console.error('Error joining group:', err));
      }
    }

    return () => {
      connection?.off?.('NewMessage');
      connection?.off?.('Typing');
      connection?.off?.('StoppedTyping');
    };
  }, [connection, refetchChatThreads, activeContact]);

  const handleTranslateMessage = async (messageId, languageCode) => {
    if (!activeContact?.jobApplication?.id || !messageId) return;
    setIsTranslating(true);
    try {
      const translatedMessage = await ChatService.translateMessage({
        messageId,
        languageCode,
      });

      setChatMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === messageId
            ? { ...translatedMessage } // Replace the entire object with the translated object
            : message
        )
      );
      setIsTranslating(false);
    } catch (error) {
      setIsTranslating(false);
      console.error('Error translating message:', error);
    }
  };

  const handleSpeakMessage = async (
    messageId,
    messageTranslationKey,
    languageCode,
    content
  ) => {
    setIsLoadingSpeak(true);
    try {
      const speakedMessageResponse = await ChatService.speakMessage({
        messageId,
        messageTranslationKey,
        languageCode,
        content,
      });
      setSpeakedMessage(speakedMessageResponse);
      setIsLoadingSpeak(false);
    } catch (error) {
      setIsLoadingSpeak(false);
      console.error('Error translating message:', error);
    }
  };

  useEffect(() => {
    if (speakedMessage?.url) {
      setIsPlayingMessage(true); // Notify that playback has started
      const audio = new Audio(speakedMessage.url);

      audio.onended = () => {
        setSpeakedMessage(null); // Reset the message after playback
        setIsPlayingMessage(false); // Notify that playback has ended
      };

      audio.play().catch((error) => {
        console.error('Error playing audio:', error);
        setSpeakedMessage(null); // Reset on error
        setIsPlayingMessage(false); // Notify playback stopped
      });
    }
  }, [speakedMessage]);

  const handleTyping = () => {
    const groupName = `${'group'}-${activeContact.id}`;
    console.log('User is typing');
    if (connection) {
      if (!isTyping) {
        setIsTyping(true);
        connection.send('Typing', groupName, userData?.userProfile?.fullName);
      }

      if (typingTimeout) clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        setIsTyping(false);
        connection.send(
          'StoppedTyping',
          groupName,
          userData?.userProfile?.fullName
        );
      }, 3000);
    }
  };

  return (
    <div className={classes.ChatWindow}>
      <MessagesList
        chatMessages={chatMessages}
        activeContact={activeContact}
        refetchMessages={refetchMessages}
        messageListHeight={messageListHeight}
        onTranslate={handleTranslateMessage}
        onSpeak={handleSpeakMessage}
        isPlayingMessage={isPlayingMessage}
        isLoadingSpeak={isLoadingSpeak}
        isTranslating={isTranslating}
      />
      <MessageInput
        activeContact={activeContact}
        refetchMessages={refetchMessages}
        handleTyping={handleTyping}
        isOthersTyping={isOthersTyping}
      />
    </div>
  );
}
