import React, { useEffect } from 'react';

import NoMessages from './NoMessages';
import RightPanelHeader from './RightPanelHeader';
import ChatWindow from './ChatWindow';

import { COMHUB_HUB_URL } from '../../../../constants/main';
import useComHubConnection from '../../../../hooks/useComHubConnection';
import classes from './styles.module.scss';

export default function RightPanel({
  activeContact,
  refetchChatThreads,
  messageListHeight,
  isJobApplicationOpened,
}) {
  const { connection } = useComHubConnection(COMHUB_HUB_URL);

  useEffect(() => {
    if (connection) {
      connection.on('NewMessage', () => {
        refetchChatThreads();
      });
    }
  }, [connection, refetchChatThreads]);

  return (
    <div className={classes.RightPanel}>
      {!activeContact ? (
        <NoMessages />
      ) : (
        <>
          <RightPanelHeader
            refetchChatThreads={refetchChatThreads}
            activeContact={activeContact}
            isJobApplicationOpened={isJobApplicationOpened}
          />
          <ChatWindow
            refetchChatThreads={refetchChatThreads}
            activeContact={activeContact}
            connection={connection}
            messageListHeight={messageListHeight}
          />
        </>
      )}
    </div>
  );
}
